import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Login = () => {
  const [data, setData] = useState({
    username: "", // Inisialisasi dengan nilai string kosong
    password: "", // Inisialisasi dengan nilai string kosong
  });

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    setData({ ...data, [name]: value });
    console.log(data);
  };

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      getData();
    }
  }

  async function getData(event) {
    event.preventDefault();
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY + "/login",
      headers: {},
      data: {
        username: data.username,
        password: data.password,
      },
    })
      .then((res) => {
        if (res.data.status == "success") {
          console.log(res.data.token);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("nama", res.data.data.nama_panggilan);
          window.location.href = "/";
        } else {
          alert("Data anda salah");
        }
      })
      .catch((err) => {
        alert("Error Login");
      });
  }

  return (
    <>
      <div class="container-scroller">
        <div class="container-fluid page-body-wrapper full-page-wrapper">
          <div class="content-wrapper d-flex align-items-center auth px-0">
            <div class="row w-100 mx-0">
              <div class="col-lg-4 mx-auto">
                <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                  <h4>Hello! let's get started</h4>
                  <h6 class="font-weight-light">Sign in to continue.</h6>
                  <form class="pt-3" onSubmit={getData}>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Username or Email"
                        name="username"
                        value={data.username}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control form-control-lg"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        name="password"
                        value={data.password}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div class="mt-3">
                      <button
                        class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        type="submit"
                      >
                        SIGN IN
                      </button>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div class="my-2 d-flex justify-content-center align-items-center">
                      <Link to="/check-jadwal">
                        <div class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                          Check Jadwal
                        </div>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
