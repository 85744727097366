import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FileBase64 from "react-file-base64";
import { Link } from "react-router-dom";

const ResetPassword = (props) => {
  const [data, setData] = useState([]);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    // Check if the value is empty or unchecked checkbox, and set it to undefined in that case
    const inputValue =
      value === "" || (event.target.type === "checkbox" && !checked)
        ? undefined
        : value;
    setData({ ...data, [name]: inputValue });
    console.log(data);
  };

  function ubahPassword(event) {
    if (event) {
      event.preventDefault();
    }

    axios({
      method: "PUT",
      url: process.env.REACT_APP_API_KEY + "/reset-password",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    }).then((res) => {
      if (res.data.status == "success") {
        alert(res.data.status);
      } else {
        alert(res.data.error);
      }
    });
  }

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Ubah Password</h4>
                <br />
                <form class="form-sample" onSubmit={ubahPassword}>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Password</label>
                        <div class="col-sm-9">
                          <input
                            type="password"
                            class="form-control"
                            name="password"
                            value={data.password}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Confirm Password
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="password"
                            class="form-control"
                            name="confirm_password"
                            value={data.confirm_password}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button variant="primary" type="submit">
                    Send
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ResetPassword;
