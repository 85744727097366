import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FileBase64 from "react-file-base64";
import { useParams } from "react-router-dom";

const EditAnggota = (props) => {
  let { id } = useParams();
  const [filter, setFilter] = useState({});
  const [photo, setPhoto] = useState();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(null);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    // Check if the value is empty or unchecked checkbox, and set it to undefined in that case
    const inputValue =
      value === "" || (event.target.type === "checkbox" && !checked)
        ? undefined
        : value;
    setData({ ...data, [name]: inputValue });
    console.log(data);
  };

  useEffect(() => {
    getListUser();
  }, []);

  function getListUser(event) {
    if (event) {
      event.preventDefault();
    }

    axios({
      method: "GET",
      url: process.env.REACT_APP_API_KEY + "/get-detail?user_id=" + id,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    }).then((res) => {
      if (res.data.status == "success") {
        setData(res.data.data);
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  function tambahuser(event) {
    event.preventDefault();

    axios({
      method: "PUT",
      url: process.env.REACT_APP_API_KEY + "/update",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    }).then((res) => {
      if (res.data.status == "success") {
        alert("success");
        window.location.href = "/anggota";
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  function getFiles(files) {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY + "/upload-file",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {
        nama_file: files.name,
        file: files.base64,
      },
    }).then((res) => {
      if (res.data.error == null) {
        setPhoto(res.data.result.url);
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Data User</h4>
                <form class="form-sample" onSubmit={tambahuser}>
                  <p class="card-description">Personal info</p>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Nama Panggilan
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="nama_panggilan"
                            value={data.nama_panggilan}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Password</label>
                        <div class="col-sm-9">
                          <input
                            type="password"
                            class="form-control"
                            name="password"
                            value={data.password}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Email</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="email"
                            value={data.email}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Nama Lengkap
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="nama_lengkap"
                            value={data.nama_lengkap}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Angkatan</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="angkatan"
                            value={data.angkatan}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Lingkungan
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="lingkungan"
                            value={data.lingkungan}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Wilayah</label>
                        <div class="col-sm-9">
                          <select
                            id=""
                            class="form-control"
                            name="wilayah"
                            value={data.wilayah}
                            onChange={handleInputChange}
                          >
                            <option value="" hidden>
                              Pilih
                            </option>
                            <option value="Tengah">Tengah</option>
                            <option value="Selatan">Selatan</option>
                            <option value="Tambun">Tambun</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">PA/PS</label>
                        <div class="col-sm-9">
                          <select
                            id=""
                            class="form-control"
                            name="pa_ps"
                            value={data.pa_ps}
                            onChange={handleInputChange}
                          >
                            <option value="" hidden>
                              Pilih
                            </option>
                            <option value="PA">PA</option>
                            <option value="PS">PS</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Role</label>
                        <div class="col-sm-9">
                          <select
                            id=""
                            class="form-control"
                            name="role"
                            value={data.role}
                            onChange={handleInputChange}
                          >
                            <option value="" hidden>
                              Pilih
                            </option>
                            <option value="Pengurus">Pengurus</option>
                            <option value="Anggota">Anggota</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Nama Ortu</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="nama_ortu"
                            value={data.nama_ortu}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          No. WA. Ortu
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="nomer_wa_ortu"
                            value={data.nomer_wa_ortu}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">No. WA</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="nomer_wa"
                            value={data.nomer_wa}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Tanggal Lahir
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="date"
                            class="form-control"
                            name="tanggal_lahir"
                            value={data.tanggal_lahir}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Sekolah</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="nama_sekolah"
                            value={data.nama_sekolah}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Notes</label>
                        <div class="col-sm-9">
                          <textarea
                            type="text"
                            class="form-control"
                            name="notes"
                            value={data.notes}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Status</label>
                        <div class="col-sm-9">
                          <select
                            id=""
                            class="form-control"
                            name="status"
                            value={data.status}
                            onChange={handleInputChange}
                          >
                            <option value="Active">Active</option>
                            <option value="Not Active">Not Active</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button variant="primary" type="submit">
                    Send
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EditAnggota;
