import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FileBase64 from "react-file-base64";
import { useParams } from "react-router-dom";
import { PieChart } from "react-chartkick";
import "chartkick/chart.js";

const EditPenjadwalan = (props) => {
  let { id } = useParams();
  const [filter, setFilter] = useState({});
  const [photo, setPhoto] = useState();
  const [data, setData] = useState([]);
  const [dataPAPS, setDataPAPS] = useState("");
  const [petugasNotIn, setPetugasNotIn] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setDataPAPS();
  };

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    // Check if the value is empty or unchecked checkbox, and set it to undefined in that case
    const inputValue =
      value === "" || (event.target.type === "checkbox" && !checked)
        ? undefined
        : value;
    setData({ ...data, [name]: inputValue });
    console.log(data);
  };

  useEffect(() => {
    getDataList();
  }, []);

  function getDataList(event) {
    if (event) {
      event.preventDefault();
    }

    axios({
      method: "GET",
      url: process.env.REACT_APP_API_KEY + "/detail-misa?id=" + id,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    }).then((res) => {
      if (res.data.status == "success") {
        setData(res.data.data);
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  function deletePetugas(id) {
    axios({
      method: "Delete",
      url: process.env.REACT_APP_API_KEY + "/deletePetugas?id=" + id,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    }).then((res) => {
      if (res.data.status == "success") {
        getDataList();
        alert(res.data.message);
      } else {
        alert(res.data.message);
      }
    });
  }

  function tambahuser(user_id) {
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_API_KEY +
        "/asignUserTugas?misa_id=" +
        id +
        "&user_id=" +
        user_id,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    }).then((res) => {
      if (res.data.status == "success") {
        getPetugasNotInMIsa("");
        getDataList();
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  function openPopUp(data) {
    setDataPAPS(data);
    setShow(true);
    getPetugasNotInMIsa("");
  }

  function tambahPetugas(id) {}

  function getPetugasNotInMIsa(nama) {
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_API_KEY +
        "/userNotPetugasInMisa?misa_id=" +
        id +
        "&tanggal=" +
        data.tanggal_misa +
        "&nama=" +
        nama +
        "&pa_ps=" +
        dataPAPS,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    }).then((res) => {
      if (res.data.status == "success") {
        setPetugasNotIn(res.data.data);
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Detail Misa</h4>

                <p class="card-description">Informasi Misa</p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Nama Misa</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          name="nama"
                          value={data.nama}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">
                        Tanggal Misa
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          name="tanggal_misa"
                          value={data.tanggal_misa}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Jam Misa</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          name="jam_misa"
                          value={data.jam_misa}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Lokasi</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          name="lokasi"
                          value={data.lokasi}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Status</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          name="status"
                          value={data.status}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Detail Petugas</h4>
                <p class="card-description">Informasi Petugas PA</p>
                <div className="d-flex justify-content-end">
                  <Button variant="info" onClick={() => openPopUp("PA")}>
                    Tambah Petugas
                  </Button>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Nama Petugas PA</th>
                            <th>Petugas Pengganti</th>
                            <th>Jam Absen</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.list_petugas &&
                            data.list_petugas.pa &&
                            data.list_petugas.pa.map((detail, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{detail["nama_user_jadwal"]}</td>
                                <td>{detail["nama_user_pengganti"]}</td>
                                <td>
                                  {detail["jam_datang"]} -{" "}
                                  {detail["jam_pulang"]}
                                </td>
                                <td>
                                  <Button
                                    variant="danger"
                                    onClick={() => deletePetugas(detail["id"])}
                                  >
                                    Hapus
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Detail Petugas</h4>
                <p class="card-description">Informasi Petugas PS</p>
                <div className="d-flex justify-content-end">
                  <Button variant="info" onClick={() => openPopUp("PS")}>
                    Tambah Petugas
                  </Button>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Nama Petugas PS</th>
                            <th>Petugas Pengganti</th>
                            <th>Jam Absen</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.list_petugas &&
                            data.list_petugas.ps &&
                            data.list_petugas.ps.map((detail, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{detail["nama_user_jadwal"]}</td>
                                <td>{detail["nama_user_pengganti"]}</td>
                                <td>
                                  {detail["jam_datang"]} -{" "}
                                  {detail["jam_pulang"]}
                                </td>
                                <td>
                                  <Button
                                    variant="danger"
                                    onClick={() => deletePetugas(detail["id"])}
                                  >
                                    Hapus
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={show}>
          <Modal.Header>
            <Modal.Title>Detail Petugas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="form-group" style={{ margin: "10px" }}>
              <p>Nama Petugas</p>
              <div>
                <input
                  type="text"
                  class="form-control"
                  name="nama"
                  onChange={(event) => getPetugasNotInMIsa(event.target.value)}
                />
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Nama Petugas</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {petugasNotIn &&
                      petugasNotIn.map((detail, index) => (
                        <tr key={index}>
                          <td>
                            <p>{detail["nama_lengkap"]}</p>
                            <p style={{ color: "red" }}>
                              {detail["keterangan"]}
                            </p>
                          </td>
                          <td>
                            <Button
                              variant="info"
                              onClick={() => tambahuser(detail["id"])}
                            >
                              Tugaskan
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
};

export default EditPenjadwalan;
