import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login/login";
import AnggotaList from "./pages/anggota/anggotaList";
import TambahAnggota from "./pages/anggota/tambahAnggota";
import ViewAnggota from "./pages/anggota/viewAnggota";
import EditAnggota from "./pages/anggota/editAnggota";
import Router from "./Layout";
import { useState } from "react";
import React, { useEffect } from "react";
import Dashboard from "./pages/dashboard/dashboard";
import PenjadwalanList from "./pages/penjadwalan/penjadwalanList";
import ViewPenjadwalan from "./pages/penjadwalan/viewPenjadwalan";
import TambahJadwalOtomatis from "./pages/penjadwalan/tambahJadwalOtomatis";
import TambahJadwalSatuan from "./pages/penjadwalan/tambahJadwalSatuan";
import TambahJadwalBulk from "./pages/penjadwalan/tambahJadwalBulk";
import JadwalList from "./pages/jadwal/jadwalList";
import EditPenjadwalan from "./pages/penjadwalan/editPenjadwalan";
import ResetPassword from "./pages/reset-password/resetPassword";
import CheckJadwal from "./pages/check-jadwal/checkJadwal";
import TugasPengganti from "./pages/dashboard/tugasPengganti";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    console.log("Component berhasil di-mount ke dalam DOM");
    if (localStorage.getItem("token") != null) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={isAuthenticated ? <Dashboard /> : <Login />}
        />
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/anggota"
          element={isAuthenticated ? <AnggotaList /> : <Login />}
        />
        <Route
          exact
          path="/tambah-anggota"
          element={isAuthenticated ? <TambahAnggota /> : <Login />}
        />
        <Route
          exact
          path="/view-anggota/:id"
          element={isAuthenticated ? <ViewAnggota /> : <Login />}
        />
        <Route
          exact
          path="/edit-anggota/:id"
          element={isAuthenticated ? <EditAnggota /> : <Login />}
        />
        <Route
          exact
          path="/penjadwalan"
          element={isAuthenticated ? <PenjadwalanList /> : <Login />}
        />
        <Route
          exact
          path="/view-penjadwalan/:id"
          element={isAuthenticated ? <ViewPenjadwalan /> : <Login />}
        />
        <Route
          exact
          path="/edit-penjadwalan/:id"
          element={isAuthenticated ? <EditPenjadwalan /> : <Login />}
        />
        <Route
          exact
          path="/tambah-penjadwalan-otomatis"
          element={isAuthenticated ? <TambahJadwalOtomatis /> : <Login />}
        />
        <Route
          exact
          path="/tambah-penjadwalan-satuan"
          element={isAuthenticated ? <TambahJadwalSatuan /> : <Login />}
        />
        <Route
          exact
          path="/tambah-penjadwalan-bulk"
          element={isAuthenticated ? <TambahJadwalBulk /> : <Login />}
        />
        <Route
          exact
          path="/jadwal"
          element={isAuthenticated ? <JadwalList /> : <Login />}
        />
        <Route
          exact
          path="/reset-password"
          element={isAuthenticated ? <ResetPassword /> : <Login />}
        />
         <Route
          exact
          path="/tugas-pengganti"
          element={isAuthenticated ? <TugasPengganti /> : <Login />}
        />
        <Route exact path="/check-jadwal" element={<CheckJadwal />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
