import { Link } from "react-router-dom";
import $ from "jquery";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

function Layout({ children }) {
  const location = useLocation();
  const [activeCollapse, setActiveCollapse] = useState(null);
  const [Profile, setProfile] = useState([]);

  useEffect(() => {
    getProfile();
  }, []);

  function getProfile() {
    axios({
      method: "GET",
      url: process.env.REACT_APP_API_KEY + "/get-profile",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    })
      .then((res) => {
        if (res.data.status === "success") {
          setProfile(res.data.data);
          console.log(Profile);
        } else {
          logout();
        }
      })
      .catch((error) => {
        console.error("Error in getProfile request:", error);
        alert("Mohon untuk login ulang");
        logout();
      });
  }

  // Function to toggle the active collapse
  const toggleCollapse = (collapseId) => {
    setActiveCollapse((prevCollapse) =>
      prevCollapse === collapseId ? null : collapseId
    );
  };

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("nama");
    window.location.href = "/";
  }

  const menu = [
    {
      nama: "Dashboard",
      link: "/",
      icon: "mdi mdi-view-dashboard",
      access: ["Anggota", "Pengurus"],
    },
    {
      nama: "Tugas Pengganti",
      link: "/tugas-pengganti",
      icon: "mdi mdi-view-dashboard",
      access: ["Anggota", "Pengurus"],
    },
    {
      nama: "Anggota",
      link: "/anggota",
      icon: "mdi mdi-view-dashboard",
      access: ["Admin", "Pengurus"],
    },
    {
      nama: "Penjadwalan",
      link: "/penjadwalan",
      icon: "mdi mdi-view-dashboard",
      access: ["Admin", "Pengurus"],
    },
    {
      nama: "Jadwal",
      link: "/jadwal",
      icon: "mdi mdi-view-dashboard",
      access: ["Pengurus", "Admin"],
    },
    {
      nama: "Reset Password",
      link: "/reset-password",
      icon: "mdi mdi-view-dashboard",
      access: ["Pengurus", "Admin", "Anggota"],
    },
  ];

  useEffect(() => {
    const path = location.pathname;
    let activeMenuNama = "";

    // Loop through the menu array to find the matching link
    menu.forEach((data) => {
      // Check if the link is a match
      if (data.link === path) {
        activeMenuNama = data.nama;
      } else if (data.children) {
        // If the link is not a match, check the children array
        data.children.forEach((child) => {
          if (child.link === path) {
            activeMenuNama = data.nama;
          }
        });
      }
    });

    // Set the activeCollapse value based on the matched data.nama or an empty string if not found
    setActiveCollapse(activeMenuNama);
  }, [location.pathname]);

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
          <ul className="nav">
            {menu.map((data, index) => {
              // Check if the user's role has access to this menu item
              if (data.access.includes(Profile["role"])) {
                return (
                  <li className="nav-item" key={index}>
                    {data.link ? (
                      <a
                        className={`nav-link ${
                          activeCollapse === data.nama ? "text-dark" : ""
                        }`}
                        aria-expanded={
                          activeCollapse === data.nama ||
                          location.pathname === data.link
                            ? "true"
                            : "false"
                        }
                        href={data.link}
                      >
                        <i
                          className={data.icon}
                          style={{ fontSize: "18px" }}
                        ></i>
                        &nbsp;&nbsp;
                        <span className="menu-title">{data.nama}</span>
                      </a>
                    ) : (
                      <a
                        className={`nav-link ${
                          activeCollapse === data.nama ? "text-dark" : ""
                        }`}
                        onClick={() => toggleCollapse(data.nama)}
                        aria-expanded={
                          activeCollapse === data.nama ? "true" : "false"
                        }
                        aria-controls={data.nama}
                      >
                        <i
                          className={data.icon}
                          style={{ fontSize: "18px" }}
                        ></i>{" "}
                        &nbsp;&nbsp;
                        <span className="menu-title">{data.nama}</span>
                        <i className="menu-arrow"></i>
                      </a>
                    )}

                    {data.children && (
                      <div
                        className={`collapse ${
                          activeCollapse === data.nama ? "show" : ""
                        }`}
                        id={data.nama}
                      >
                        <div>
                          <ul className="nav flex-column sub-menu">
                            {data.children.map((children, childIndex) => (
                              <li className="nav-item" key={childIndex}>
                                <a
                                  href={children.link}
                                  className={`nav-link ${
                                    location.pathname === children.link
                                      ? "text-dark"
                                      : ""
                                  }`}
                                >
                                  {children.nama}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </li>
                );
              }
              return null; // Hide menu item if user's role doesn't have access
            })}
            <li className="nav-item" onClick={() => logout()}>
              <a
                className={"nav-link  text-dark"}
                aria-expanded={false}
                href={""}
              >
                <i className={""} style={{ fontSize: "18px" }}></i>
                &nbsp;&nbsp;
                <span className="menu-title">Logout</span>
              </a>
            </li>
          </ul>
        </nav>

        <div className="main-panel">
          <div className="content-wrapper">{children}</div>
          <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                Copyright © 2021. Merak Core Organization System - All rights
                reserved.
              </span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                Hand-crafted & made with
                <i className="ti-heart text-danger ml-1"></i> Panitia TAPA 2024
              </span>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

export default Layout;
