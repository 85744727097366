import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";

const CheckJadwal = () => {
  const [data, setData] = useState("");
  const [result, setResult] = useState([]);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    setData(value);
    console.log(data);
  };

  useEffect(() => {
    getData();
  }, [data]);

  async function getData() {
    axios({
      method: "GET",
      url: process.env.REACT_APP_API_KEY + "/get-jadwal-by-name?nama=" + data,
      headers: {},
      data: {},
    })
      .then((res) => {
        if (res.data.status == "success") {
          setResult(res.data.data);
          console.log(res.data.data);
        } else {
          alert("Data anda salah");
        }
      })
      .catch((err) => {
        alert("Error CheckJadwal");
      });
  }

  return (
    <>
      <div class="container-scroller">
        <div class="container-fluid page-body-wrapper full-page-wrapper">
          <div class="content-wrapper " style={{ marginTop: "3em" }}>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title text-center">Cek Jadwal</h4>
                    <form class="form-sample">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <p class="col-sm-5 col-form-label">
                              Masukkan Nama Lengkap
                            </p>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                name="nama"
                                value={data}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="row">
                      {result.map((detail, index) => (
                        <div className="col-12 col-md-4" key={index}>
                          <div className="table-responsive border rounded" style={{marginTop:'30px'}}>
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th colSpan={2} className="text-center">
                                    {detail.nama_misa}
                                    <br />
                                    <br />
                                    {detail.tanggal_misa}
                                  </th>
                                </tr>
                                <tr>
                                  <th>Nama Petugas</th>
                                  <th>PA/PS</th>
                                </tr>
                              </thead>
                              <tbody>
                                {detail.petugas.map((petugas, index) => (
                                  <tr key={index}>
                                    <td>{petugas.nama_petugas}</td>
                                    <td>{petugas.pa_ps}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckJadwal;
