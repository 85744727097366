import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FileBase64 from "react-file-base64";
import { useParams } from "react-router-dom";
import { PieChart } from "react-chartkick";
import "chartkick/chart.js";

const ViewPenjadwalan = (props) => {
  let { id } = useParams();
  const [filter, setFilter] = useState({});
  const [photo, setPhoto] = useState();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    // Check if the value is empty or unchecked checkbox, and set it to undefined in that case
    const inputValue =
      value === "" || (event.target.type === "checkbox" && !checked)
        ? undefined
        : value;
    setData({ ...data, [name]: inputValue });
    console.log(data);
  };

  useEffect(() => {
    getListUser();
  }, []);

  function getListUser(event) {
    if (event) {
      event.preventDefault();
    }

    axios({
      method: "GET",
      url: process.env.REACT_APP_API_KEY + "/detail-misa?id=" + id,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    }).then((res) => {
      if (res.data.status == "success") {
        setData(res.data.data);
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  function tambahuser(event) {
    event.preventDefault();

    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY + "/register",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    }).then((res) => {
      if (res.data.status == "success") {
        alert("success");
        window.location.href = "/anggota";
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Detail Misa</h4>

                <p class="card-description">Informasi Misa</p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Nama Misa</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          name="nama"
                          value={data.nama}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">
                        Tanggal Misa
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          name="tanggal_misa"
                          value={data.tanggal_misa}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Jam Misa</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          name="jam_misa"
                          value={data.jam_misa}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Lokasi</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          name="lokasi"
                          value={data.lokasi}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Status</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          name="status"
                          value={data.status}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Nama Petugas PA</th>
                            <th>Petugas Pengganti</th>
                            <th>Jam Absen</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.list_petugas &&
                            data.list_petugas.pa &&
                            data.list_petugas.pa.map((detail, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{detail["nama_user_jadwal"]}</td>
                                <td>{detail["nama_user_pengganti"]}</td>
                                <td>
                                  {detail["jam_datang"]} -{" "}
                                  {detail["jam_pulang"]}
                                </td>
                                <td>
                                  <Button
                                    variant="danger"
                                    onClick={() => handleShow(detail["id"])}
                                  >
                                    Detail
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-12">
                    <br />
                    <br />
                    <br />
                    <br />

                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Nama Petugas PS</th>
                            <th>Petugas Pengganti</th>
                            <th>Jam Absen</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.list_petugas &&
                            data.list_petugas.ps &&
                            data.list_petugas.ps.map((detail, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{detail["nama_user_jadwal"]}</td>
                                <td>{detail["nama_user_pengganti"]}</td>
                                <td>
                                  {detail["jam_datang"]} -{" "}
                                  {detail["jam_pulang"]}
                                </td>
                                <td>
                                  <Button
                                    variant="danger"
                                    onClick={() => handleShow(detail["id"])}
                                  >
                                    Detail
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Rangkuman Misa</h4>

                <p class="card-description">Informasi Statistik Petugas</p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <div class="table-responsive mb-3 mb-md-0 mt-3">
                        <table class="table table-borderless report-table">
                          {data && data.statistik ? (
                            data.statistik.map((detail, index) => (
                              <tr key={index}>
                                <td className="text-muted">{detail.name}</td>
                                <td className="w-100 px-0">
                                  <div className="progress progress-md mx-4">
                                    <div
                                      className="progress-bar bg-primary"
                                      role="progressbar"
                                      style={{
                                        width: `${detail.persen_data}%`,
                                      }}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </td>
                                <td>
                                  <h5 className="font-weight-bold mb-0">
                                    {detail.value_data} / {detail.total_data}
                                  </h5>
                                </td>
                              </tr>
                            ))
                          ) : (
                            // Render something if data.statistik is undefined
                            <tr>
                              <td colSpan="3">No data available</td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <PieChart data={data && data["pie_petugas_by_wilayah"]} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={show}>
            <Modal.Header>
              <Modal.Title>Detail User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="form-group" style={{ margin: "10px" }}>
                <p>Nama Petugas</p>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    name="misa_name"
                    disabled
                  />
                </div>
              </div>
              <div class="form-group" style={{ margin: "10px" }}>
                <p>Nama Petugas Pengganti</p>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    name="misa_name"
                    disabled
                  />
                </div>
              </div>
              <div class="form-group" style={{ margin: "10px" }}>
                <p>Status User</p>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    name="misa_name"
                    disabled
                  />
                </div>
              </div>
              <div class="form-group" style={{ margin: "10px" }}>
                <p>Jam Absen Masuk</p>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    name="misa_name"
                    disabled
                  />
                </div>
              </div>
              <div class="form-group" style={{ margin: "10px" }}>
                <p>Jam Absen Keluar</p>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    name="misa_name"
                    disabled
                  />
                </div>
              </div>
              <div class="table-responsive mb-3 mb-md-0 mt-3">
                <table class="table table-borderless report-table">
                  <tr>
                    <td class="text-muted">Tugas Sesuai Jadwal</td>
                    <td class="w-100 px-0">
                      <div class="progress progress-md mx-4">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "70%" }}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-weight-bold mb-0">7</h5>
                    </td>
                  </tr>

                  <tr>
                    <td class="text-muted">Tugas Pengganti</td>
                    <td class="w-100 px-0">
                      <div class="progress progress-md mx-4">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "70%" }}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-weight-bold mb-0">7</h5>
                    </td>
                  </tr>

                  <tr>
                    <td class="text-muted">
                      Tugas sesuai jadwal dalam 3 bulan
                    </td>
                    <td class="w-100 px-0">
                      <div class="progress progress-md mx-4">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "20%" }}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-weight-bold mb-0">2</h5>
                    </td>
                  </tr>
                </table>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Hapus Data
              </Button>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Layout>
    </>
  );
};

export default ViewPenjadwalan;
