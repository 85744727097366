import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FileBase64 from "react-file-base64";
import { Link } from "react-router-dom";

const PenjadwalanList = (props) => {
  const [filter, setFilter] = useState({
    status: "open",
  });
  const [photo, setPhoto] = useState();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(null);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    // Check if the value is empty or unchecked checkbox, and set it to undefined in that case
    const inputValue =
      value === "" || (event.target.type === "checkbox" && !checked)
        ? undefined
        : value;
    setFilter({ ...filter, [name]: inputValue });
  };

  useEffect(() => {
    getListUser();
  }, []);

  function getListUser(event) {
    if (event) {
      event.preventDefault();
    }

    axios({
      method: "GET",
      url:
        process.env.REACT_APP_API_KEY +
        "/list-misa?nama=" +
        encodeURIComponent(filter.nama || "") +
        "&tanggal=" +
        encodeURIComponent(filter.tanggal || "") +
        "&status=" +
        encodeURIComponent(filter.status || ""),
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    }).then((res) => {
      if (res.data.status == "success") {
        setData(res.data.data);
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  function DeleteData(id) {
    axios({
      method: "DELETE",
      url: process.env.REACT_APP_API_KEY + "/deleteMisaAndPetugas?misa_id=" + id,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    }).then((res) => {
      if (res.data.status == "success") {
        alert("Data di hapus");
        getListUser();
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">List Misa</h4>
                <br />
                <div class="d-flex">
                  <div class="p-2">
                    <form class="form-inline" onSubmit={getListUser}>
                      <label class="sr-only" for="inlineFormInputName2">
                        Nama / Email
                      </label>
                      <input
                        type="text"
                        class="form-control mb-2 mr-sm-2"
                        placeholder="Nama"
                        name="nama"
                        value={filter.nama}
                        onChange={handleInputChange}
                      />
                      <label class="sr-only" for="inlineFormInputName2">
                        Tanggal
                      </label>
                      <input
                        name="tanggal"
                        value={filter.tanggal}
                        id=""
                        type="date"
                        class="form-control mb-2 mr-sm-2"
                        onChange={handleInputChange}
                      />

                      <label class="sr-only" for="inlineFormInputName2">
                        Status
                      </label>
                      <select
                        name="status"
                        value={filter.status}
                        id=""
                        type="text"
                        class="form-control mb-2 mr-sm-2"
                        onChange={handleInputChange}
                      >
                        <option value="">Semua</option>
                        <option value="open">open</option>
                        <option value="close">close</option>
                      </select>

                      <button type="submit" class="btn btn-primary mb-2">
                        Filter
                      </button>
                    </form>
                  </div>

                  <div class=" ml-auto p-2 btn-group">
                    <button
                      type="button"
                      class="btn btn-info mb-2 dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Buat Jadwal
                    </button>
                    <div class="dropdown-menu">
                      <a
                        href="/tambah-penjadwalan-otomatis"
                        class="btn mb-2 dropdown-item"
                      >
                        Jadwal Otomatis
                      </a>
                      <a
                        href="/tambah-penjadwalan-satuan"
                        class="btn mb-2 dropdown-item"
                      >
                        Jadwal Satuan
                      </a>
                      <a
                        href="/tambah-penjadwalan-bulk"
                        class="btn mb-2 dropdown-item"
                      >
                        Jadwal Bulk
                      </a>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama Misa</th>
                        <th>Tanggal Misa</th>
                        <th>Jam Misa</th>
                        <th>Lokasi</th>
                        <th>Status</th>
                        <th>Jumlah Petugas Jadwal</th>
                        <th>Jumlah Petugas</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((detail, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="py-1">{detail.nama}</td>
                          <td>{detail.tanggal_misa}</td>
                          <td>{detail.jam_misa}</td>
                          <td>{detail.lokasi}</td>
                          <td>{detail.status}</td>
                          <td>{detail.jumlah_petugas}</td>
                          <td>{detail.jumlah_petugas_bertugas}</td>
                          <td>
                            <div class="d-flex flex-row">
                              <Link
                                class="p-2"
                                to={"/view-penjadwalan/" + detail.id}
                              >
                                <i className="mdi mdi-send"></i>
                              </Link>
                              <Link
                                class="p-2"
                                to={"/edit-penjadwalan/" + detail.id}
                              >
                                <i className="mdi mdi-grease-pencil"></i>
                              </Link>
                              <div
                                class="p-2"
                                onClick={() => DeleteData(detail.id)}
                              >
                                <i className="mdi mdi-delete"></i>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PenjadwalanList;
