import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {Routes, Route, useNavigate} from 'react-router-dom';

const TambahJadwalSatuan = (props) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState({});
  const [photo, setPhoto] = useState();
  const [data, setData] = useState({
    wilayah: [], // Initialize wilayah as an empty array
    misa: [], // Initialize misa as an empty array
  });
  const [inputFields, setInputFields] = useState([{ value: "" }]);
  const [showModal, setShowModal] = useState(null);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    // Check if the value is empty or unchecked checkbox, and set it to undefined in that case
    const inputValue =
      value === "" || (event.target.type === "checkbox" && !checked)
        ? undefined
        : value;

    // If the input is a checkbox, update wilayah array based on checked values
    if (event.target.type === "checkbox") {
      const updatedWilayah = data.wilayah.slice(); // Create a copy of the wilayah array
      if (checked) {
        updatedWilayah.push(value); // Add the value to the array if checked
      } else {
        const index = updatedWilayah.indexOf(value);
        if (index !== -1) {
          updatedWilayah.splice(index, 1); // Remove the value from the array if unchecked
        }
      }
      setData({ ...data, [name]: updatedWilayah }); // Update the wilayah array
    } else {
      // If it's not a checkbox, update other form fields
      setData({ ...data, [name]: inputValue });
    }
  };

  function tambahuser(event) {
    event.preventDefault();

    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY + "/create-misa",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    }).then((res) => {
      if (res.data.status == "success") {
        alert("success");

        navigate("/edit-penjadwalan/" + res.data.id);
      } else {
        alert("Data Error, Please Try Again");
      }
    });
  }

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Buat jadwal satuan</h4>
                <form class="form-sample" onSubmit={tambahuser}>
                  <p class="card-description">
                    Periksa kembali hasil data yang dibuat !
                  </p>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Tanggal Misa
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="date"
                            class="form-control"
                            name="tanggal_misa"
                            value={data.tanggal_misa}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Jam Misa</label>
                        <div class="col-sm-9">
                          <input
                            type="time"
                            class="form-control"
                            name="jam_misa"
                            value={data.jam_misa}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Lokasi</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="lokasi"
                            value={data.lokasi}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Nama Misa</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="nama"
                            value={data.nama}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button variant="primary" type="submit">
                    Send
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TambahJadwalSatuan;
