import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FileBase64 from "react-file-base64";
import * as XLSX from "xlsx";

const TambahJadwalBulk = (props) => {
  const [filter, setFilter] = useState({});
  const [photo, setPhoto] = useState();
  const [data, setData] = useState({});
  const [inputFields, setInputFields] = useState([{ value: "" }]);
  const [showModal, setShowModal] = useState(null);

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets["Jadwal Fix"];
        const json = XLSX.utils.sheet_to_json(worksheet);

        // Proses pengelompokan data
        const groupedData = [];
        const groupedMap = new Map();

        // Iterasi melalui data hasil pembacaan Excel
        json.forEach((entry) => {
          const namaMisa = entry["Nama Misa"];
          let tanggalMisa = entry["Tanggal Misa"];
          let jamMisa = entry["Jam Misa"];
          let lokasiMisa = entry["Lokasi Misa"];

          // Periksa tipe data tanggalMisa
          if (typeof tanggalMisa === "string" && tanggalMisa.includes("-")) {
            const parts = tanggalMisa.split("-");
            if (parts.length === 3) {
              tanggalMisa = `${parts[0]}-${parts[1]}-${parts[2]}`;
            }
          }

          // Periksa tipe data jamMisa
          if (typeof jamMisa === "string" && jamMisa.includes(".")) {
            jamMisa = jamMisa.replace(/\./g, ":");
          }

          // Buat kunci untuk pengelompokan
          const groupKey = `${namaMisa}-${tanggalMisa}`;

          // Cek apakah kelompok sudah ada di map
          if (!groupedMap.has(groupKey)) {
            // Jika belum, tambahkan kelompok baru
            groupedMap.set(groupKey, {
              "Nama Misa": namaMisa,
              "Tanggal Misa": tanggalMisa,
              "Jam Misa": jamMisa,
              "Lokasi Misa": lokasiMisa,
              Data: [],
            });
          }

          // Tambahkan data ke dalam kelompok yang sesuai
          groupedMap.get(groupKey).Data.push(entry);
        });

        // Konversi map ke array objek
        groupedMap.forEach((value) => {
          groupedData.push(value);
        });

        setData({ data: groupedData });
        console.log({ data: groupedData });
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  function DownloadContoh() {
    axios({
      method: "GET",
      url: process.env.REACT_APP_API_KEY + "/get_ref_user_for_jadwal",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    }).then((res) => {
      if (res.data.status == "success") {
        const sheet1Data = res.data.data;
        // Data untuk sheet kedua
        const sheet2Data = [
          [
            "Nama Misa",
            "Lokasi Misa",
            "Tanggal Misa",
            "Jam Misa",
            "Id",
            "Nama",
            "wilayah",
            "PA/PS",
          ],
        ];

        // Buat workbook kosong
        const workbook = XLSX.utils.book_new();

        // Buat sheet pertama
        const sheet1 = XLSX.utils.aoa_to_sheet(sheet1Data);
        XLSX.utils.book_append_sheet(workbook, sheet1, "List User");

        // Buat sheet kedua
        const sheet2 = XLSX.utils.aoa_to_sheet(sheet2Data);
        XLSX.utils.book_append_sheet(workbook, sheet2, "Jadwal Fix");

        // Simpan workbook ke file XLSX
        XLSX.writeFile(workbook, "Format Upload Jadwal.xlsx");
      } else {
        alert("Data Error, Please Try Again");
      }
    });
  }

  function uploadFIle(event) {
    event.preventDefault();

    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY + "/create-misa-excel",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    }).then((res) => {
      if (res.data.status == "success") {
        alert("success");
        window.location.href = "/penjadwalan";
      } else {
        alert("Data Error, Please Try Again");
      }
    });
  }

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Buat jadwal bulk</h4>
                <form class="form-sample" onSubmit={uploadFIle}>
                  <p class="card-description">
                    Download contoh excel berikut, lalu upload kembali dengan
                    format yang sesuai <br></br>
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={DownloadContoh}
                    >
                      Contoh Format Jadwal dalam Excel
                    </span>
                  </p>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Upload file
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="file"
                            class="form-control"
                            name="upload"
                            id="upload"
                            onChange={readUploadFile}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button variant="primary" type="submit">
                    Send
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TambahJadwalBulk;
