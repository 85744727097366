import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import axios from "axios";
import { PieChart } from "react-chartkick";
import "chartkick/chart.js";

const Dashboard = (props) => {
  const [jadwal, setJadwal] = useState([]);
  const [pie, setPie] = useState([]);
  const [report, setReport] = useState([]);
  const [nama, setName] = useState(localStorage.getItem("nama"));
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });

  useEffect(() => {
    getDataJadwal();
    getLocation();
    getReportData();
  }, []);

  function getLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          setLocation(position.coords);

          console.log(position.coords);
        },
        (error) => {
          setLocation({ error: `Error: ${error.message}` });
        }
      );
    } else {
      setLocation({ error: "Geolocation is not available." });
      alert("Mohon aktifkan lokasi Anda.");
    }
    console.log(location);
  }

  function getDataJadwal() {
    axios({
      method: "GET",
      url: process.env.REACT_APP_API_KEY + "/get-detail-perorang",

      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    }).then((res) => {
      if (res.data.status == "success") {
        setJadwal(res.data.data);
        console.log(res.data.data);
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  function checkInAndOut(id, checkIn, checkOut) {
    var status;
    if (!checkIn) {
      var status = "check-in";
    } else if (checkIn && !checkOut) {
      var status = "check-out";
    }
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_API_KEY +
        "/absen?id=" +
        id +
        "&status=" +
        status +
        "&latitude=" +
        location.latitude +
        "&longitude=" +
        location.longitude,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    }).then((res) => {
      if (res.data.status == "success") {
        //setJadwal(res.data.data);
        getDataJadwal();
        console.log(res.data.data);
        alert(status + " berhasil");
      } else {
        alert(res.data.message);
      }
    });
  }

  function getReportData() {
    axios({
      method: "GET",
      url: process.env.REACT_APP_API_KEY + "/detail-statistik-user",

      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    }).then((res) => {
      if (res.data.status == "success") {
        setReport(res.data.data);
        const transformedData = res.data.data.map((item) => [
          ["Sesuai Jadwal", item.total_jadwal_sesuai_jadwal],
          ["Tugas Digantikan", item.total_jadwal_digantikan],
          ["Absen Bertugas", item.total_jadwal_absen],
        ]);
        setPie(transformedData);
        console.log(transformedData);
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  return (
    <>
      <Layout>
        <div class="row">
          <div class="col-md-12 grid-margin">
            <div class="row">
              <div class="col-12 col-xl-8 mb-4 mb-xl-0">
                <h3 class="font-weight-bold">Welcome {nama}</h3>
                <h6 class="font-weight-normal mb-0">
                  kamu memiliki{" "}
                  <span style={{ color: "red" }}>
                    {jadwal && jadwal["hari_ini"] && jadwal["hari_ini"].length}{" "}
                    jadwal tugas hari ini!
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 grid-margin transparent">
            <div class="row">
              {jadwal && jadwal["hari_ini"] && jadwal["hari_ini"][0] && (
                <div class="col-md-4 mb-4 stretch-card transparent">
                  <div
                    class="card card-tale"
                    onClick={() =>
                      checkInAndOut(
                        jadwal["hari_ini"][0]["id"],
                        jadwal["hari_ini"][0]["jam_datang"],
                        jadwal["hari_ini"][0]["jam_pulang"]
                      )
                    }
                  >
                    <div class="card-body">
                      <p class="mb-4">Jadwal hari ini</p>
                      <p class="fs-30 mb-2">
                        Tugas {jadwal["hari_ini"][0]["misa_nama"]}
                      </p>
                      <p>
                        {jadwal["hari_ini"][0]["tanggal_misa"]},{" "}
                        {jadwal["hari_ini"][0]["jam_misa"]}
                      </p>
                      {!jadwal["hari_ini"][0]["jam_datang"] && (
                        <p>Klik untuk check in</p>
                      )}
                      {jadwal["hari_ini"][0]["jam_datang"] &&
                        !jadwal["hari_ini"][0]["jam_pulang"] && (
                          <p>Klik untuk check out</p>
                        )}
                    </div>
                  </div>
                </div>
              )}

              {jadwal &&
                jadwal["jadwal"] &&
                jadwal["jadwal"].map((data) => (
                  <div
                    className="col-md-4"
                    style={{ marginTop: "10px" }}
                    key={data.id}
                  >
                    <div className="card card-light-blue">
                      <div className="card-body">
                        <p className="mb-4">Jadwal terdekat</p>
                        <p className="fs-30 mb-2">Tugas {data.misa_nama}</p>
                        <p>
                          {data.tanggal_misa}, {data.jam_misa}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {report && (
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card position-relative">
                <div class="card-body">
                  <div
                    id="detailedReports"
                    class="carousel slide detailed-report-carousel position-static pt-2"
                    data-ride="carousel"
                  >
                    <div class="carousel-inner">
                      {report.map((data, index) => (
                        <div
                          key={index}
                          className={`carousel-item ${
                            index === report.length - 1 ? "active" : ""
                          }`}
                        >
                          <div class="row">
                            <div class="col-md-12 col-xl-3 d-flex flex-column justify-content-start">
                              <div class="ml-xl-4 mt-3">
                                <p class="card-title">Detailed Reports</p>
                                <h1 class="text-primary">
                                  {data.total_jadwal_bulan_ini} jadwal
                                </h1>
                                <h3 class="font-weight-500 mb-xl-4 text-primary">
                                  pada bulan {data.month}
                                </h3>
                              </div>
                            </div>
                            <div class="col-md-12 col-xl-9">
                              <div class="row">
                                <div class="col-md-6 border-right">
                                  <div class="table-responsive mb-3 mb-md-0 mt-3">
                                    <table class="table table-borderless report-table">
                                      <tr>
                                        <td class="text-muted">
                                          Tugas Sesuai Jadwal
                                        </td>
                                        <td class="w-100 px-0">
                                          <div class="progress progress-md mx-4">
                                            <div
                                              class="progress-bar bg-primary"
                                              role="progressbar"
                                              style={{
                                                width:
                                                  (data.total_jadwal_sesuai_jadwal /
                                                    data.total_jadwal_bulan_ini) *
                                                  100,
                                              }}
                                              aria-valuenow={
                                                data.total_jadwal_bulan_ini
                                              }
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </td>
                                        <td>
                                          <h5 class="font-weight-bold mb-0">
                                            {data.total_jadwal_sesuai_jadwal}
                                          </h5>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td class="text-muted">
                                          Ijin Bertugas
                                        </td>
                                        <td class="w-100 px-0">
                                          <div class="progress progress-md mx-4">
                                            <div
                                              class="progress-bar bg-primary"
                                              role="progressbar"
                                              style={{
                                                width:
                                                  (data.total_jadwal_digantikan /
                                                    data.total_jadwal_bulan_ini) *
                                                  100,
                                              }}
                                              aria-valuenow={
                                                data.total_jadwal_bulan_ini
                                              }
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </td>
                                        <td>
                                          <h5 class="font-weight-bold mb-0">
                                            {data.total_jadwal_digantikan}
                                          </h5>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td class="text-muted">
                                          Tidak Bertugas
                                        </td>
                                        <td class="w-100 px-0">
                                          <div class="progress progress-md mx-4">
                                            <div
                                              class="progress-bar bg-primary"
                                              role="progressbar"
                                              style={{
                                                width:
                                                  (data.total_jadwal_absen /
                                                    data.total_jadwal_bulan_ini) *
                                                  100,
                                              }}
                                              aria-valuenow={
                                                data.total_jadwal_bulan_ini
                                              }
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </td>
                                        <td>
                                          <h5 class="font-weight-bold mb-0">
                                            {data.total_jadwal_absen}
                                          </h5>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                  <PieChart data={pie[index]} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <a
                      class="carousel-control-prev"
                      href="#detailedReports"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a
                      class="carousel-control-next"
                      href="#detailedReports"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default Dashboard;
