import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FileBase64 from "react-file-base64";

const TambahJadwalOtomatis = (props) => {
  const [filter, setFilter] = useState({});
  const [photo, setPhoto] = useState();
  const [data, setData] = useState({
    wilayah: [], // Initialize wilayah as an empty array
    misa: [], // Initialize misa as an empty array
  });
  const [inputFields, setInputFields] = useState([{ value: "" }]);
  const [showModal, setShowModal] = useState(null);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    // Check if the value is empty or unchecked checkbox, and set it to undefined in that case
    const inputValue =
      value === "" || (event.target.type === "checkbox" && !checked)
        ? undefined
        : value;

    // If the input is a checkbox, update wilayah array based on checked values
    if (event.target.type === "checkbox") {
      const updatedWilayah = data.wilayah.slice(); // Create a copy of the wilayah array
      if (checked) {
        updatedWilayah.push(value); // Add the value to the array if checked
      } else {
        const index = updatedWilayah.indexOf(value);
        if (index !== -1) {
          updatedWilayah.splice(index, 1); // Remove the value from the array if unchecked
        }
      }
      setData({ ...data, [name]: updatedWilayah }); // Update the wilayah array
    } else {
      // If it's not a checkbox, update other form fields
      setData({ ...data, [name]: inputValue });
    }
  };

  const handleChangeInput = (index, event) => {
    const { name, value, checked } = event.target;
    const values = [...inputFields];
    values[index][name] = value;
    setInputFields(values);
    console.log(inputFields);
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ value: "" });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  function tambahuser(event) {
    event.preventDefault();

    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY + "/create-jadwal-otomatis",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: { ...data, ["misa"]: inputFields },
    }).then((res) => {
      if (res.data.status == "success") {
        alert("success");
        window.location.href = "/penjadwalan";
      } else {
        alert("Data Error, Please Try Again");
      }
    });
  }

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Buat jadwal otomatis</h4>
                <form class="form-sample" onSubmit={tambahuser}>
                  <p class="card-description">
                    Periksa kembali hasil data yang dibuat !
                  </p>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Tanggal Mulai
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="date"
                            class="form-control"
                            name="start_date"
                            value={data.start_date}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Lokasi</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="lokasi"
                            value={data.lokasi}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Jumlah Minimal Petugas PA
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="number"
                            class="form-control"
                            name="jumlah_petugas_pa"
                            value={data.jumlah_petugas_pa}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Jumlah Minimal Petugas PS
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="number"
                            class="form-control"
                            name="jumlah_petugas_ps"
                            value={data.jumlah_petugas_ps}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Wilayah</label>
                        <div class="col-sm-9">
                          <p>
                            <input
                              type="checkbox"
                              name="wilayah"
                              value="tengah"
                              onChange={handleInputChange}
                              checked={data.wilayah.includes("tengah")}
                            />
                            Tengah
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              name="wilayah"
                              value="selatan"
                              onChange={handleInputChange}
                              checked={data.wilayah.includes("selatan")}
                            />
                            Selatan
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              name="wilayah"
                              value="tambun"
                              onChange={handleInputChange}
                              checked={data.wilayah.includes("tambun")}
                            />
                            Tambun
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <Button variant="warning" onClick={handleAddFields}>
                        Tambah Data
                      </Button>
                      {inputFields.map((inputField, index) => (
                        <div className="row" key={index}>
                          <div class="form-group" style={{ margin: "10px" }}>
                            <p>Nama Misa</p>
                            <div>
                              <input
                                type="text"
                                class="form-control"
                                name="misa_name"
                                value={inputField.misa_name}
                                onChange={(event) =>
                                  handleChangeInput(index, event)
                                }
                                required
                              />
                            </div>
                          </div>
                          <div class="form-group" style={{ margin: "10px" }}>
                            <p>Hari Misa</p>
                            <div>
                              <input
                                type="text"
                                class="form-control"
                                name="hari_misa"
                                value={inputField.hari_misa}
                                onChange={(event) =>
                                  handleChangeInput(index, event)
                                }
                                required
                              />
                            </div>
                          </div>
                          <div class="form-group" style={{ margin: "10px" }}>
                            <p>Jam Misa</p>
                            <div>
                              <input
                                type="time"
                                class="form-control"
                                name="misa_time"
                                value={inputField.misa_time}
                                onChange={(event) =>
                                  handleChangeInput(index, event)
                                }
                                required
                              />
                            </div>
                          </div>
                          <div class="form-group" style={{ margin: "10px" }}>
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveFields(index)}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <Button variant="primary" type="submit">
                    Send
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TambahJadwalOtomatis;
