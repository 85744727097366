import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FileBase64 from "react-file-base64";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const TugasPengganti = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [misa, setMisa] = useState([]);
  const [petugas, setPetugas] = useState([]);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    // Check if the value is empty or unchecked checkbox, and set it to undefined in that case
    const inputValue =
      value === "" || (event.target.type === "checkbox" && !checked)
        ? undefined
        : value;
    setData({ ...data, [name]: inputValue });
    console.log(data);
  };

  useEffect(() => {
    getMisa();
  }, [location]);

  function getMisa() {
    axios({
      method: "GET",
      url: process.env.REACT_APP_API_KEY + "/get-ref-active-misa",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    }).then((res) => {
      if (res.data.status == "success") {
        setMisa(res.data.data);
      } else {
        alert(res.data.error);
      }
    });
  }

  useEffect(() => {
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_API_KEY +
        "/get-ref-user-absen-misa?misa_id=" +
        data.misa,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    }).then((res) => {
      if (res.data.status == "success") {
        setPetugas(res.data.data);
      } else {
        alert(res.data.error);
      }
    });
  }, [data.misa]);

  function ubahPassword(event) {
    if (event) {
      event.preventDefault();
    }

    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY + "/absen-pengganti",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    }).then((res) => {
      if (res.data.status == "success") {
        alert(res.data.status);
        navigate("/");
      } else {
        alert(res.data.error);
      }
    });
  }

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Tugas Pengganti</h4>
                <br />
                <form class="form-sample" onSubmit={ubahPassword}>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Pilih Misa
                        </label>
                        <div class="col-sm-9">
                          <select
                            name="misa"
                            value={data.misa}
                            class="form-control"
                            onChange={handleInputChange}
                          >
                            <option hidden>--Pilih--</option>
                            {misa.map((data, index) => (
                              <option value={data.id} key={index}>
                                {data.nama}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Pilih Nama Petugas
                        </label>
                        <div class="col-sm-9">
                          <select
                            name="petugas_id"
                            value={data.petugas_id}
                            class="form-control"
                            onChange={handleInputChange}
                          >
                            <option hidden>--Pilih--</option>
                            {petugas.map((data, index) => (
                              <option value={data.id} key={index}>
                                {data.nama_lengkap}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button variant="primary" type="submit">
                    Send
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TugasPengganti;
