import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FileBase64 from "react-file-base64";
import { useParams } from "react-router-dom";
import { PieChart } from "react-chartkick";
import "chartkick/chart.js";

const ViewAnggota = (props) => {
  let { id } = useParams();
  const [filter, setFilter] = useState({});
  const [photo, setPhoto] = useState();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(null);
  const [pie, setPie] = useState([]);
  const [report, setReport] = useState([]);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    // Check if the value is empty or unchecked checkbox, and set it to undefined in that case
    const inputValue =
      value === "" || (event.target.type === "checkbox" && !checked)
        ? undefined
        : value;
    setData({ ...data, [name]: inputValue });
    console.log(data);
  };

  useEffect(() => {
    getListUser();
    getReportData();
  }, []);

  function getListUser(event) {
    if (event) {
      event.preventDefault();
    }

    axios({
      method: "GET",
      url: process.env.REACT_APP_API_KEY + "/get-detail?user_id=" + id,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    }).then((res) => {
      if (res.data.status == "success") {
        setData(res.data.data);
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  function getReportData() {
    axios({
      method: "GET",
      url: process.env.REACT_APP_API_KEY + "/detail-statistik-user?id=" + id,

      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    }).then((res) => {
      if (res.data.status == "success") {
        setReport(res.data.data);
        const transformedData = res.data.data.map((item) => [
          ["Sesuai Jadwal", item.total_jadwal_sesuai_jadwal],
          ["Tugas Digantikan", item.total_jadwal_digantikan],
          ["Absen Bertugas", item.total_jadwal_absen],
        ]);
        setPie(transformedData);
        console.log(transformedData);
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  function tambahuser(event) {
    event.preventDefault();

    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY + "/register",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    }).then((res) => {
      if (res.data.status == "success") {
        alert("success");
        window.location.href = "/anggota";
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  function getFiles(files) {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY + "/upload-file",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {
        nama_file: files.name,
        file: files.base64,
      },
    }).then((res) => {
      if (res.data.error == null) {
        setPhoto(res.data.result.url);
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Data User</h4>
                <form class="form-sample" onSubmit={tambahuser}>
                  <p class="card-description">Personal info</p>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Nama Panggilan
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="nama_panggilan"
                            value={data.nama_panggilan}
                            onChange={handleInputChange}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Email</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="email"
                            value={data.email}
                            onChange={handleInputChange}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Nama Lengkap
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="nama_lengkap"
                            value={data.nama_lengkap}
                            onChange={handleInputChange}
                            disabled
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Angkatan</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="angkatan"
                            value={data.angkatan}
                            onChange={handleInputChange}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Lingkungan
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="lingkungan"
                            value={data.lingkungan}
                            onChange={handleInputChange}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Wilayah</label>
                        <div class="col-sm-9">
                          <select
                            id=""
                            class="form-control"
                            name="wilayah"
                            value={data.wilayah}
                            onChange={handleInputChange}
                            disabled
                          >
                            <option value="" hidden>
                              Pilih
                            </option>
                            <option value="Tengah">Tengah</option>
                            <option value="Selatan">Selatan</option>
                            <option value="Tambun">Tambun</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">PA/PS</label>
                        <div class="col-sm-9">
                          <select
                            id=""
                            class="form-control"
                            name="pa_ps"
                            value={data.pa_ps}
                            onChange={handleInputChange}
                            disabled
                          >
                            <option value="" hidden>
                              Pilih
                            </option>
                            <option value="PA">PA</option>
                            <option value="PS">PS</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Role</label>
                        <div class="col-sm-9">
                          <select
                            id=""
                            class="form-control"
                            name="role"
                            value={data.role}
                            onChange={handleInputChange}
                            disabled
                          >
                            <option value="" hidden>
                              Pilih
                            </option>
                            <option value="Admin">Admin</option>
                            <option value="Pengurus">Pengurus</option>
                            <option value="Anggota">Anggota</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Nama Ortu</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="nama_ortu"
                            value={data.nama_ortu}
                            onChange={handleInputChange}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          No. WA. Ortu
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="nomer_wa_ortu"
                            value={data.nomer_wa_ortu}
                            onChange={handleInputChange}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">No. WA</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="nomer_wa"
                            value={data.nomer_wa}
                            onChange={handleInputChange}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Tanggal Lahir
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="date"
                            class="form-control"
                            name="tanggal_lahir"
                            value={data.tanggal_lahir}
                            disabled
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Sekolah</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="nama_sekolah"
                            value={data.nama_sekolah}
                            disabled
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Notes</label>
                        <div class="col-sm-9">
                          <textarea
                            type="text"
                            class="form-control"
                            name="notes"
                            value={data.notes}
                            disabled
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Status</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            name="status"
                            value={data.status}
                            disabled
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card position-relative">
              <div class="card-body">
                <div
                  id="detailedReports"
                  class="carousel slide detailed-report-carousel position-static pt-2"
                  data-ride="carousel"
                >
                  <div class="carousel-inner">
                    {report.map((data, index) => (
                      <div
                        key={index}
                        className={`carousel-item ${
                          index === report.length - 1 ? "active" : ""
                        }`}
                      >
                        <div class="row">
                          <div class="col-md-12 col-xl-3 d-flex flex-column justify-content-start">
                            <div class="ml-xl-4 mt-3">
                              <p class="card-title">Detailed Reports</p>
                              <h1 class="text-primary">
                                {data.total_jadwal_bulan_ini} jadwal
                              </h1>
                              <h3 class="font-weight-500 mb-xl-4 text-primary">
                                pada bulan {data.month}
                              </h3>
                            </div>
                          </div>
                          <div class="col-md-12 col-xl-9">
                            <div class="row">
                              <div class="col-md-6 border-right">
                                <div class="table-responsive mb-3 mb-md-0 mt-3">
                                  <table class="table table-borderless report-table">
                                    <tr>
                                      <td class="text-muted">
                                        Tugas Sesuai Jadwal
                                      </td>
                                      <td class="w-100 px-0">
                                        <div class="progress progress-md mx-4">
                                          <div
                                            class="progress-bar bg-primary"
                                            role="progressbar"
                                            style={{
                                              width:
                                                (data.total_jadwal_sesuai_jadwal /
                                                  data.total_jadwal_bulan_ini) *
                                                100,
                                            }}
                                            aria-valuenow={
                                              data.total_jadwal_bulan_ini
                                            }
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>
                                        </div>
                                      </td>
                                      <td>
                                        <h5 class="font-weight-bold mb-0">
                                          {data.total_jadwal_sesuai_jadwal}
                                        </h5>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td class="text-muted">Ijin Bertugas</td>
                                      <td class="w-100 px-0">
                                        <div class="progress progress-md mx-4">
                                          <div
                                            class="progress-bar bg-primary"
                                            role="progressbar"
                                            style={{
                                              width:
                                                (data.total_jadwal_digantikan /
                                                  data.total_jadwal_bulan_ini) *
                                                100,
                                            }}
                                            aria-valuenow={
                                              data.total_jadwal_bulan_ini
                                            }
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>
                                        </div>
                                      </td>
                                      <td>
                                        <h5 class="font-weight-bold mb-0">
                                          {data.total_jadwal_digantikan}
                                        </h5>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td class="text-muted">Tidak Bertugas</td>
                                      <td class="w-100 px-0">
                                        <div class="progress progress-md mx-4">
                                          <div
                                            class="progress-bar bg-primary"
                                            role="progressbar"
                                            style={{
                                              width:
                                                (data.total_jadwal_absen /
                                                  data.total_jadwal_bulan_ini) *
                                                100,
                                            }}
                                            aria-valuenow={
                                              data.total_jadwal_bulan_ini
                                            }
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>
                                        </div>
                                      </td>
                                      <td>
                                        <h5 class="font-weight-bold mb-0">
                                          {data.total_jadwal_absen}
                                        </h5>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                              <div class="col-md-6 mt-3">
                                <PieChart data={pie[index]} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <a
                    class="carousel-control-prev"
                    href="#detailedReports"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a
                    class="carousel-control-next"
                    href="#detailedReports"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ViewAnggota;
