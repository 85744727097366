import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FileBase64 from "react-file-base64";
import { Link } from "react-router-dom";

const AnggotaList = (props) => {
  const [filter, setFilter] = useState({});
  const [photo, setPhoto] = useState();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(null);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    // Check if the value is empty or unchecked checkbox, and set it to undefined in that case
    const inputValue =
      value === "" || (event.target.type === "checkbox" && !checked)
        ? undefined
        : value;
    setFilter({ ...filter, [name]: inputValue });
  };

  useEffect(() => {
    getListUser();
  }, []);

  function getListUser(event) {
    if (event) {
      event.preventDefault();
    }

    axios({
      method: "GET",
      url:
        process.env.REACT_APP_API_KEY +
        "/get-list?pa_ps=" +
        encodeURIComponent(filter.pa_ps || "") +
        "&wilayah=" +
        encodeURIComponent(filter.wilayah || "") +
        "&nama=" +
        encodeURIComponent(filter.nama || ""),

      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    }).then((res) => {
      if (res.data.status == "success") {
        setData(res.data.data);
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  function deleteUser(id) {
    axios({
      method: "DELETE",
      url: process.env.REACT_APP_API_KEY + "/delete?id=" + id,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {},
    }).then((res) => {
      if (res.data.status == "success") {
        alert("Data di hapus");
        getListUser();
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  function getFiles(files) {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY + "/upload-file",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {
        nama_file: files.name,
        file: files.base64,
      },
    }).then((res) => {
      if (res.data.error == null) {
        setPhoto(res.data.result.url);
      } else {
        alert("Data Error, Please Try Againt");
      }
    });
  }

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">List Anggota</h4>
                <br />
                <div class="d-flex">
                  <div class="p-2">
                    <form class="form-inline" onSubmit={getListUser}>
                      <label class="sr-only" for="inlineFormInputName2">
                        Nama / Email
                      </label>
                      <input
                        type="text"
                        class="form-control mb-2 mr-sm-2"
                        placeholder="Nama"
                        name="nama"
                        value={filter.nama}
                        onChange={handleInputChange}
                      />
                      <label class="sr-only" for="inlineFormInputName2">
                        PA/PS
                      </label>
                      <select
                        name="pa_ps"
                        value={filter.pa_ps}
                        id=""
                        type="text"
                        class="form-control mb-2 mr-sm-2"
                        onChange={handleInputChange}
                      >
                        <option value="">Semua</option>
                        <option value="PA">PA</option>
                        <option value="PS">PS</option>
                      </select>
                      <label class="sr-only" for="inlineFormInputName2">
                        Wilayah
                      </label>
                      <select
                        name="wilayah"
                        value={filter.wilayah}
                        id=""
                        type="text"
                        class="form-control mb-2 mr-sm-2"
                        onChange={handleInputChange}
                      >
                        <option value="">Semua</option>
                        <option value="Tengah">Tengah</option>
                        <option value="Selatan">Selatan</option>
                        <option value="Tambun">Tambun</option>
                      </select>

                      <button type="submit" class="btn btn-primary mb-2">
                        Filter
                      </button>
                    </form>
                  </div>

                  <div class="ml-auto p-2">
                    <a href="/tambah-anggota" class="btn btn-info mb-2">
                      Tambah User
                    </a>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama Lengkap</th>
                        <th>Email</th>
                        <th>PA/PS</th>
                        <th>Notes</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((detail, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{detail.nama_lengkap}</td>
                          <td>{detail.email}</td>
                          <td>{detail.pa_ps}</td>
                          <td>{detail.notes}</td>
                          <td>{detail.status}</td>
                          <td>
                            <div class="d-flex flex-row">
                              <Link
                                class="p-2"
                                to={"/view-anggota/" + detail.id}
                              >
                                <i className="mdi mdi-send"></i>
                              </Link>
                              <Link
                                class="p-2"
                                to={"/edit-anggota/" + detail.id}
                              >
                                <i className="mdi mdi-grease-pencil"></i>
                              </Link>
                              <div
                                class="p-2"
                                onClick={() => deleteUser(detail.id)}
                              >
                                <i className="mdi mdi-delete"></i>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AnggotaList;
